export const TEST_RUN_STATUS={
    running:"RUNNING",
    success:"SUCCESS",
    failed:"FAILED",
    canceled:"CANCELED"
}

export const TEST_RUN_DETAIL_OVERALL_STATUS={
    running:"RUNNING",
    finished:"FINISHED"
}

export const HTTP_REQUEST_STATUS={
    success:"SUCCESS",
    failed:"FAILED"
}