import NeumorphicCard from "../../utilities/NeumorphicCard";
import React, { useEffect, useState, useContext } from "react";
import neuStyle from "../../../css/Neumorphic.module.css";
import TextField from "@mui/material/TextField";
import { configApi } from "../../../constants/backend";
import useHttp from "../../../hooks/useHttp";
import VonageLoader from "../../utilities/VonageLoader";
import { debounce } from "../../../service/FunctionUtility";
import ClientDataView from "./utilities/ClientDataView";
import { ClientDetailResponse } from "../../../model/BackendResponse";

export default function ManageClient(){

    const [clientDetails, setClientDetails]=useState([]);
    const { isLoading, error, sendRequest } = useHttp();
    useEffect(() => {
        sendRequest(
          {
            url: configApi.getClientDetails.url,
            authentication: configApi.getClientDetails.authentication,
          },
          transformGetClientDetailsHttpData
        );
    }, []);
    
    const transformGetClientDetailsHttpData=(data)=>{
        let res=new ClientDetailResponse(data);
        console.log(res)
        setClientDetails(new ClientDetailResponse(data).clientDetailList)
    }
  
    return (
      <React.Fragment>
                <div
          style={{
            margin: "auto",
            width: "95%",
            marginBottom: "20px",
            marginTop: "20px",
            height: "70%",
            borderRadius: "10px",
            overflowY: "scroll",
          }}
        >
        <VonageLoader open={isLoading} />
        {clientDetails && clientDetails.map((data, index) => (
          <ClientDataView clientDetail={data} key={index}/>
        ))}
        </div>
      </React.Fragment>
    );
}