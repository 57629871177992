export class ExecuteTestRequest {
  requestBody;
  constructor(serviceCode, phoneNumber,testType,carrierList) {
    this.requestBody={serviceCode:serviceCode,phoneNumber:phoneNumber,testType:testType,carrier:[...carrierList]} 
  }
}

export class CreateClientRequest {
  requestBody;
  constructor(clientName,clientMail,subscriptionList) {
    this.requestBody={clientName:clientName,contact:clientMail,subscriptionList:[...subscriptionList],action:"CREATE"}
  }
}