import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import vonageLoading from '../../assets/images/vonage_logo_loading.gif'
import Avatar from '@mui/material/Avatar';

export default function VonageLoader(props) {
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.open}
      >
      <Avatar
        alt="Remy Sharp"
        src={vonageLoading}
        sx={{ width: 300, height:300}}
      />
      </Backdrop>
    </div>
  );
}
