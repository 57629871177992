import AuthContext from "../../context/AuthContext";
import { subscribe } from "../../service/EventManager";
import { SecurityEvents } from "../../constants/customEvents";
import { useEffect, useState } from "react";
import { isUserActive, getSubscriptionList } from "../../service/Authenticator";
import React from "react";
export default function Security(props) {
  const [isAuthenticated, setIsAuthenticate] = useState(false);
  const [authority, setAuthority] = useState([]);
  const [userName, setUserName] = useState();
  const allowUserAccess = () => {
    setIsAuthenticate(true);
    const subscription = getSubscriptionList();
    setAuthority(subscription.subscriptionList);
    setUserName(subscription.clientId);
  };
  const removeUserAccess = () => {
    setIsAuthenticate(false);
    setAuthority([]);
    setUserName("");
  };
  subscribe(SecurityEvents.NOT_AUTHENTICATED, removeUserAccess);
  subscribe(SecurityEvents.AUTHENTICATED, allowUserAccess);

  useEffect(() => {
    if (isUserActive()) {
      allowUserAccess();
    }
  }, []);
  return (
    <React.Fragment>
      <AuthContext.Provider
        value={{
          isAuthenticated: isAuthenticated,
          authority: authority,
          userName: userName,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    </React.Fragment>
  );
}
