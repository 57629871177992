import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertContext from "../../context/AlertContext";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const alertType = ["error", "warning", "info", "success"];


export default function AlertManager(props) {

  const [severity, setSeverity]= React.useState("info");
  const [open, setOpen]=React.useState(false)
  const [message, setMessage]=React.useState("")

  const setAlert=(severity="info",message="Request Cannot be processed now!!")=>
  {
    setSeverity(alertType.includes(severity) ? severity : "info")
    setMessage(message)
    setOpen(true)
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
    <Stack spacing={2} sx={{ width: "100%", position: "absolute" }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
    <AlertContext.Provider
            value={{
              setAlert: setAlert,
            }}>
    {props.children}
    </AlertContext.Provider>
    </>
  );
}
