import React, { useEffect, useState } from "react";
import neuStyles from "../../../../css/Neumorphic.module.css";
import StatusLoader from "./StatusLoader";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { api } from "../../../../constants/backend";
import useHttp from "../../../../hooks/useHttp";
import vonage_message from "../../../../assets/images/vonage_message.png";
import vonage_phone from "../../../../assets/images/vonage_phone.png";
import ProgressBar from "../../../utilities/ProgressBar";
import Modal from "../../../utilities/Modal";
import TestResultView from "./TestResultView";
import Tooltip from "@mui/material/Tooltip";
import vonage_detail from "../../../../assets/images/vonage_detail.png";
import { TestResultResponse } from "../../../../model/BackendResponse";
import { TestRunDetail } from "../../Tester";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { TEST_RUN_DETAIL_OVERALL_STATUS } from "../../../../constants/status";
import LoadingButton from '@mui/lab/LoadingButton';
export default function TestView(props) {
  const [httpResultResponse, setHttpResultResponse] = useState();
  const [testRunDetail, setTestRunDetail] = useState(props.testRunDetail);
  const { isLoading, error, sendRequest } = useHttp();
  const [openResultDetail, setOpenResultDetail] = useState(false);
  const [successRate, setSuccessRate] = useState(0);
  const [activeStatus, setActiveStatus] = useState({
    state: "Waiting",
    text: "Waiting",
  });
  const getTestResults = () => {
    
    sendRequest(
      {
        url: api.getTestResultsByRequestId.apiName + testRunDetail.requestId,
        authentication: api.getTestResultsByRequestId.authentication,
      },
      transformGetTestResultHttp
    );
  };

  const transformGetTestResultHttp = (data) => {
    let testResultResponse = new TestResultResponse(data);
    if (testResultResponse.status === "SUCCESS") {
      setTestRunDetail(new TestRunDetail(testResultResponse.tests[0]))
      setHttpResultResponse(testResultResponse.tests[0]);
    }
  };

  const updateStatus=()=>{
    let totalTestCount = testRunDetail.totalTest;
    let successCount = 0;
    if (testRunDetail.overallStatus === TEST_RUN_DETAIL_OVERALL_STATUS.running) {
      setActiveStatus({
        state: "Running",
        text: "Running",
      });
      setTimeout(() => getTestResults(), 10000);
    } else if (testRunDetail.overallStatus === TEST_RUN_DETAIL_OVERALL_STATUS.finished) {
      testRunDetail.testRunResultList.forEach((data) => {
        if (data.testStatus === "SUCCESS") successCount = successCount + 1;
      });
      if (successCount === 0 && testRunDetail.overallStatus === TEST_RUN_DETAIL_OVERALL_STATUS.finished)
        setActiveStatus({
          state: "Failed",
          text: testRunDetail.overallStatus,
        });
      else if (successCount < totalTestCount && testRunDetail.overallStatus ===  TEST_RUN_DETAIL_OVERALL_STATUS.finished)
        setActiveStatus({
          state: "PartialSuccess",
          text: testRunDetail.overallStatus,
        });
      else if (successCount == totalTestCount && testRunDetail.overallStatus === TEST_RUN_DETAIL_OVERALL_STATUS.finished)
        setActiveStatus({
          state: "Success",
          text: testRunDetail.overallStatus,
        });
      setSuccessRate((successCount * 100) / totalTestCount);
    }
  }
  useEffect(updateStatus, [testRunDetail]);
  const killTestHandler=()=>{
    setStoppingTest(true)
    sendRequest(
      {
        url: api.stopTest.url + testRunDetail.requestId,
        method: api.stopTest.method,
        authentication: api.stopTest.authentication,
      },
      ()=>{}
    );
  }
  const[stoppingTest, setStoppingTest]= useState(false);
  return (
    <React.Fragment>
      <Modal
        open={openResultDetail}
        setOpen={(data) => setOpenResultDetail(data)}
        title={"Test Results"}
      >
        <TestResultView
          testData={httpResultResponse ? httpResultResponse : {}}
        />
      </Modal>
      <div
        style={{
          margin: "auto",
          marginBottom: "20px",
          marginTop: "20px",
          width: "42vw",
          height:"16%"
        }}
        className={neuStyles["neumorphic-outer"]}
      >
        <ListItem style={{ height: "10vh" }}>
          <ListItemAvatar>
            <div style={{ marginTop: "5vh" }}>
              <StatusLoader key={props.key} status={activeStatus} />
            </div>
          </ListItemAvatar>
          <ListItemText
            sx={{ width: "3vw" }}
            primary={`${testRunDetail.phoneNumber}`}
            primaryTypographyProps={{
              fontSize: "1.3vw",
              fontWeight: "bolder",
              color: "#881fff",
              fontFamily: "Quicksand, sans-serif"
            }}
            secondary={
              <img
                src={
                  testRunDetail.testType === "SMS"
                    ? vonage_message
                    : vonage_phone
                }
                width="30vw"
                height="30vh"
              />
            }
          />
          <ListItemText
            primary={`${testRunDetail.requestId}`}
            primaryTypographyProps={{
              fontSize: "1vw",
              fontWeight: "bolder",
              color: "#185BF1",
              fontFamily: "Quicksand, sans-serif"
            }}
          />

          <Tooltip title={"Details"} placement="top-end">
            <button
              type="button"
              className={neuStyles["neumorphic-btn"]}
              style={{ width: "3.5vw", height: "3vw" }}
              onClick={() => setOpenResultDetail(true)}
            >
              <img src={vonage_detail} width="100%" height="99%" />
            </button>
          </Tooltip>
        </ListItem>
        <ProgressBar successRate={successRate} />
        {activeStatus.state === "Running" && (
          <Tooltip title="Stop">
              <LoadingButton
              loadingIndicator="Stopping..."
                color="error"
                size="small"
                onClick={killTestHandler}
                loading={stoppingTest}
                sx={{
                  marginTop: "-200px",
                  position: "relative",
                  marginLeft: "-300px",
                  border:"none",
                  width:"90px"
                }}
                loadingPosition="center"
                variant="outlined"
                
              >
                <DisabledByDefaultIcon sx={{ fontSize: "20px" }} />
              </LoadingButton>
              </Tooltip>
            )}
      </div>
    </React.Fragment>
  );
}
