import {useCallback, useState } from "react";
import { endPoint } from "../constants/backend";
import { getAccessToken } from "../service/Authenticator";
export default function useHttp() {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const sendRequest = useCallback(async (requestConfig, applyData, authentication) => {
        setIsLoading(true)
        setError(null)
        let headerHolder={};
        if(requestConfig.authentication==="Basic")
        {
            headerHolder={...headerHolder,'Authorization':"Basic "+ btoa(requestConfig.user+":"+requestConfig.pass)}
        }
        if(requestConfig.authentication==="Token")
        {
            const token=await getAccessToken();
            if(!token)
            {
                setError("Session Expired. Login to continue.")
                return;
            }
            headerHolder={...headerHolder,'Authorization':"Bearer "+ token}
        }
        if(requestConfig.headers)
        {
            headerHolder={...headerHolder, ...requestConfig.headers} 
        }
        try {
            const response = await fetch(`${endPoint}${requestConfig.url}`, {
                method: requestConfig.method ? requestConfig.method : 'GET',
                headers: headerHolder,
                body: requestConfig.body ? JSON.stringify(requestConfig.body) : null
            });
            if (!response.ok) {
                throw new Error('Request failed')
            }
            const data = await response.json();
            applyData(data)
        }
        catch (err) {
            setError(err.message || "Service Not available")
        }
        setIsLoading(false);
    }, [])
    return {
        isLoading,
        error,
        sendRequest
    }
}