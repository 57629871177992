import TestExecuter from "./testExecuter/TestExecuter";
import TestLogger from "./testLogger/TestLogger";
import React, { useState, useEffect} from "react";
import Stack from "@mui/material/Stack";
import TestRunContext from "../../context/TestRunContext";
import useHttp from "../../hooks/useHttp";
import { TestResultResponse } from "../../model/BackendResponse";
import { api } from "../../constants/backend";
import { TEST_RUN_STATUS,TEST_RUN_DETAIL_OVERALL_STATUS, HTTP_REQUEST_STATUS } from "../../constants/status";

export class TestRunDetail {
  requestId;
  phoneNumber;
  totalTest;
  overallStatus=null;
  testType;
  testRunResultList = [];
  constructor(testResultResponse) {
    this.requestId = testResultResponse.requestId;
    this.phoneNumber = testResultResponse.testResults[0].phoneNumber;
    this.totalTest = testResultResponse.testResults.length;
    if (this.requestId.substring(3, 6) === "SMS") this.testType = "SMS";
    testResultResponse.testResults.forEach((element) => {
      if (!this.overallStatus && element.testStatus === TEST_RUN_STATUS.running) {
        this.overallStatus = TEST_RUN_DETAIL_OVERALL_STATUS.running;
      }
      this.testRunResultList.push(
        new TestRunResult(element.carrier, element.message, element.testStatus)
      );
    })
    if(this.overallStatus===null)
      this.overallStatus = TEST_RUN_DETAIL_OVERALL_STATUS.finished;
  }
}
class TestRunResult{
  carrier;
  message;
  testStatus;
  constructor(carrier, message, testStatus){
    this.carrier=carrier;
    this.message=message;
    this.testStatus=testStatus;
  }
}

export default function Tester() {
  const [testRuns, addTestRuns]= useState([])
  const { isLoading, error, sendRequest } = useHttp();
  const pushTest=(data)=>{
    data.requestId && sendRequest(
      {
        url: api.getTestResultsByRequestId.apiName + data.requestId,
        authentication: api.getTestResultsByRequestId.authentication,
      },
      transformGetTestResultHttp
    );
  }
  
  const transformGetTestResultHttp = (data) => {
    let testResultResponse = new TestResultResponse(data);
    if (testResultResponse.status === HTTP_REQUEST_STATUS.success) {
      testResultResponse.tests.forEach(test=>{
        addTestRuns(prev=>{
          return [new TestRunDetail(test),...prev]
        })
      })

    }
  };
  return (
    <React.Fragment>
      <TestRunContext.Provider value={{
        testRuns: testRuns,
        pushTest: pushTest
      }}>
      <Stack
        direction="row"
        spacing={3}
        sx={{ marginTop: "30px", height: "80%"}}
      >
        <TestExecuter />
        <TestLogger />
      </Stack>
      </TestRunContext.Provider>
    </React.Fragment>
  );
}
