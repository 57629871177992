import Tester from "../components/tester/Tester";
import Report from "../components/report/Report";
import Admin from "../components/admin/Admin";
import Account from "../components/account/Account";
export const routesConfig = [
  {
    path: "test",
    exact: true,
    component: Tester,
    private: true,
  },
  {
    path: "",
    exact: true,
    component: Tester,
    private: true,
  },
  {
    path: "report",
    component: Report,
    private: true,
  },
  {
    path: "admin",
    component: Admin,
    private: true,
  },
  {
    path: "account",
    component: Account,
    private: true,
  },
  {
    path: "logout",
    component: Report,
    private: true,
  },
];
