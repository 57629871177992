import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import neuStyles from "../../css/Neumorphic.module.css";
import { Stack } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import vonage_network from "../../assets/images/vonage_network.png";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CellTowerIcon from "@mui/icons-material/CellTower";
const theme = {
  List: {
    select: {
      width: "45%",
      height: "150%",
      overflow: "auto",
      border: "1px solid #FF5F1F",
      marginRight: "15px",
      marginLeft: "15px",
    },
    view: {
      width: "45%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "scroll",
      marginRight: "15px",
      marginLeft: "15px",
    },
  },
  ListItem: {
    select: {
      width: "100%",
      borderRadius: "10px",
    },
    view: {
      margin: "auto",
      marginTop: "10px",
      width: "80%",
      borderRadius: "10px",
    },
  },
  ListSubheader: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    width: "30vw",
    height: "30px",
  },
};

export default function DataListSelector(props) {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(props.data);
  const [right, setRight] = React.useState([]);
  const [activeList, setActiveList] = React.useState(props.data);
  React.useEffect(() => {
    setLeft(props.data);
    setActiveList(props.data);
    setChecked([]);
  }, [props.data]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setRight(newChecked);
    props.setSelectedData(newChecked);
  };

  const customList = (items, isDisplay) => (
    <List sx={theme.List.select} className={neuStyles["neumorphic-inner"]}>
      <ListSubheader sx={theme.ListSubheader}>
        <Stack direction={"row"} spacing={"25vw"}>
          <img src={vonage_network} width="30" height="30" />
        </Stack>
      </ListSubheader>
      {items.length === 0 && !isDisplay && (
        <div>
          Click <CellTowerIcon /> to add carriers
        </div>
      )}
      {items.map((value) => {
        const labelId = `transfer-list-all-item-${value}-label`;
        return (
          <ListItem
            key={value}
            role="listitem"
            button
            onClick={handleToggle(value)}
            sx={theme.ListItem.select}
          >
            <ListItemIcon>
              <Checkbox
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                sx={{
                  color: "#a3aab9",
                  margin: "auto 30px",
                  fontSize: "100px",
                }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{ color: "#a3aab9", margin: "auto", fontSize: "100px" }}
              id={labelId}
              primary={`${value}`}
              primaryTypographyProps={{fontWeight: 'bold',fontFamily: "Quicksand, sans-serif"}} 
            />
            <IconButton
              aria-label="delete"
              onClick={() => props.deleteData(value)}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        );
      })}
      <ListItem />
    </List>
  );

  return (
    <React.Fragment>
      <Stack
        sx={{
          height: "45vh",
          marginTop: "6vh",
          marginLeft: "6vw",
          width: "70vw",
        }}
        spacing={3}
      >
        {customList(activeList)}
      </Stack>
    </React.Fragment>
  );
}
