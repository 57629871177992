import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(
    props,
    ref,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal(props) {
    const handleClose = () => {
        props.setOpen(false);
    };
    return (
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={"xl"}
                maxWidth={"20px"}
                
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {props.children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Done
          </Button>
        </DialogActions>
            </Dialog>
    );
}
