import React, { useEffect, useState } from "react";
import neuStyle from "../../../../css/Neumorphic.module.css";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SimpleTable from "../../../utilities/SimpleTable";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import { debounce } from "../../../../service/FunctionUtility";
import useHttp from "../../../../hooks/useHttp";
import { configApi } from "../../../../constants/backend";
import { ServiceResponse } from "../../../../model/BackendResponse";
const customStyle = {
    button: {
      style: { width: "6vw", height: "3vw", marginLeft: "10px" },
      styleClass: neuStyle["neumorphic-btn"],
    },
    table: {
      style: {
        overflow: "scroll",
        height: "30vh",
        borderRadius: "10px",
        margin: "auto",
        marginTop: "10px",
        border: "solid",
      },
    },
    formBox: {
      style: { "& .MuiTextField-root": { m: 1, width: "25ch" } },
    },
    titleText: {
      style: {
        fontWeight: "bolder",
        color: "#a3aab9",
      },
    },
  };
export default function SubscriptionForm(props){
    const { isLoading, error, sendRequest } = useHttp();
    const[serviceList, setServiceList]= useState([]);
    const [serviceNameIdMap, setServiceNameIdMap] = useState();
    useEffect(()=>{
      sendRequest(
        {
          url: configApi.getServices.url,
          authentication: configApi.getServices.authentication,
        },
        transformGetServiceHttpData
      )
    },[])
    const transformGetServiceHttpData=(data)=>{
      let serviceResponse=new ServiceResponse(data);
      setServiceList(serviceResponse.serviceList);
      const tempMap=new Map();
      serviceResponse.serviceList.forEach(element => {
        tempMap.set(element.serviceName,{code:element.serviceCode,type:element.serviceType})
      });
      setServiceNameIdMap(tempMap);
    }
    const [allService, setAllService] = useState([])
    const [availableSvc, setAvailableSvc] = useState([]);
    const columns = ["Name", "Code","Type", "Limit", "Delete"];
    const [selectedService, setSelectedService] = useState(availableSvc[0]);
    useEffect(() => {
      if (props.subscription.length === 0) {
        setAvailableSvc(allService);
      }
    }, [props.subscription]);
    useEffect(() => {
      setAllService(serviceList.map(service=>service.serviceName))
      setAvailableSvc(serviceList.map(service=>service.serviceName))
    }, [serviceList]);
    const rows = props.subscription.map((data) =>[
      data.serviceName,
      data.serviceCode,
      serviceNameIdMap.get(data.serviceName).type,
      <input
        type="number"
        onChange={debounce((event) => {
          onChangeLimitHandler(data.serviceName, event.target.value);
        }, 1000)}
        defaultValue={data.limit}
      />,
      <IconButton
        color="error"
        aria-label="delete"
        size="large"
        onClick={() => onRemoveSvcHandler(data.serviceName)}
      >
        <DeleteIcon />
      </IconButton>,
    ]);
    const onAddSvcHandler = () => {
      if (selectedService) {
        props.setSubscription((prev) => [
          ...prev,
          { serviceName:selectedService,serviceCode: serviceNameIdMap.get(selectedService).code, type:serviceNameIdMap.get(selectedService).type, limit: 100 },
        ]);
        setAvailableSvc((prev) => prev.filter((data) => data != selectedService));
        setSelectedService();
      }
    };
  
    const onRemoveSvcHandler = (serviceName) => {
      props.setSubscription((prev) => {
        for (let i = 0; i < prev.length; i++) {
          if (prev[i].serviceName === serviceName) {
            let temp = prev;
            temp.splice(i, 1);
            setAvailableSvc((prev) => [...prev, serviceName]);
            return temp;
          }
        }
        return prev;
      });
    };
  
    const onChangeLimitHandler = (service, newLimit) => {
      props.setSubscription((prev) => {
        for (let i = 0; i < prev.length; i++) {
          if (prev[i].serviceName === service) {
            let temp = prev;
            temp[i]["limit"] =newLimit ;
            return temp;
          }
        }
        return prev;
      });
    };
    return (
      <React.Fragment>
        <Typography sx={customStyle.titleText.style}>
          {"Add Subscription"}
        </Typography>
        <Stack direction="row" sx={{ width: "100%" }}>
          <div>
            <Autocomplete
              onChange={debounce((event, newValue) =>
                setSelectedService(newValue)
              )}
              value={selectedService}
              disablePortal
              size="small"
              id="combo-box-demo"
              options={availableSvc}
              sx={{ width: 230, height: "1px", paddingTop: 2, margin: "auto" }}
              renderInput={(params) => (
                <TextField
                  className={neuStyle["neumorphic-inner"]}
                  value={selectedService}
                  sx={{ borderRadius: "0px" }}
                  {...params}
                  label="Select Test Service"
                />
              )}
            />
          </div>
          <div>
            <IconButton
              aria-label="delete"
              size="large"
              onClick={onAddSvcHandler}
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
          </div>
        </Stack>
        <div style={customStyle.table.style}>
          <SimpleTable columns={columns} rows={rows}></SimpleTable>
        </div>
      </React.Fragment>
    );
  };