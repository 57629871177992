import React, { useEffect } from "react";
import neuStyles from "../../../../css/Neumorphic.module.css";
import ProgressBar from "../../../utilities/ProgressBar";
import SimpleTable from "../../../utilities/SimpleTable";
import Popover from "@mui/material/Popover";
import style from "../../../../css/tester/TestResultView.module.css";
import { useState } from "react";

const CircularPrgress=(props)=>{
  const getStatusColor = (rate) => {
    if (rate === 100)
      return "url(#GradientColor-100)";
    else if (rate >= 70 && rate < 100)
      return "url(#GradientColor-70)";
    else if (rate >= 50 && rate < 70)
      return "url(#GradientColor-50)";
    else if (rate >= 20 && rate < 50)
      return "url(#GradientColor-20)";
    else if (rate < 20)
      return "url(#GradientColor-0)";
    else
      return "url(#GradientColor-0)";
  };

  return (
    <div className={style["circular-progress"]}>
      <div className={style["circular-progress-outer"]}>
        <div className={style["circular-progress-inner"]}>
          <div className={style["cicular-text"]} style={{ paddingTop: "24px" }}>
            {`${Math.round(props.rate)}%`}
          </div>
          <div className={style["cicular-text"]}>Success</div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="200px"
          height="200px"
          className={style["circular-progress-bar"]}
        >
          <linearGradient id="GradientColor-100">
            <stop offset="0%" stopColor="rgba(0,185,29,1)" />
            <stop offset="100%" stopColor="rgba(249,249,249,1)" />
          </linearGradient>
          <linearGradient id="GradientColor-70">
            <stop offset="0%" stopColor="rgba(199,223,11,1)" />
            <stop offset="100%" stopColor="rgba(249,249,249,1)" />
          </linearGradient>
          <linearGradient id="GradientColor-50">
            <stop offset="0%" stopColor="rgba(242,164,1,1)" />
            <stop offset="100%" stopColor="rgba(249,249,249,1)" />
          </linearGradient>
          <linearGradient id="GradientColor-20">
            <stop offset="0%" stopColor="rgba(253,117,29,1)" />
            <stop offset="100%" stopColor="rgba(249,249,249,1)" />
          </linearGradient>
          <linearGradient id="GradientColor-0">
            <stop offset="0%" stopColor="rgba(253,29,29,1)" />
            <stop offset="100%" stopColor="rgba(253,29,29,1)" />
          </linearGradient>
          <circle
            cx="104"
            cy="74"
            r="60"
            style={{
              strokeDashoffset: props.rate?375 - (props.rate * 375) / 100:375,
              stroke: getStatusColor(props.rate),
            }}
            className={style["circular-progress-circle"]}
          />
        </svg>
      </div>
    </div>
  );
}
const TestSummary = (props) => {
  return (
    <React.Fragment>
      <div className={style["summary-wrapper"]}>
        {props.summary.map((data,index) => (
          <div className={style["summary-column"]} key={index}>
            <div className={style["summary-key-text"]}>{data.key}</div>
            <div className={style["summary-value-text"]}>{data.value}</div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default function TestResultView(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [detail, updateDetail] = useState();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const showDetail = (event, detail) => {
    updateDetail(detail);
    setAnchorEl(event.currentTarget);
  };
  const ResultDetail = (props) => {
    return (
      <>
        {detail && (
          <div className={style["result-detail"]}>
            {Object.keys(detail).map((key) => (
              <div className={style["result-detail-row"]}>
                <div className={style["result-detail-key"]}>{key}:</div>
                <div className={style[`${detail[key]? "result-detail-value": "result-detail-value-na"}`]}>
                  {detail[key] ? detail[key] : "NA"}
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  try {
    const getStatus = (status) => {
      if (status === "SUCCESS")
        return <div className={`${style["status"]} ${style["status-success"]}`}>{status}</div>;
      else if (status === "RUNNING")
        return  <div className={`${style["status"]} ${style["status-runnning"]}`}>{status}</div>;
      else 
        return  <div className={`${style["status"]} ${style["status-failed"]}`}>{status}</div>;
    };
    const requestId = props.testData.requestId;
    const testType = requestId.substring(3, 6) === "SMS" ? "SMS" : "VOICE";
    const successTestCount = props.testData.testResults.filter((data) => data.testStatus === "SUCCESS").length;
    const testSummary = [
      { key: "Request Id:", value: requestId },
      { key: "Test Type:", value: testType },
      { key: "Total Test Cases:", value: props.testData.testResults.length },
      { key: "Test Date:", value: props.testData.testDate ? props.testData.testDate : "NA"}];
    const columns = ["PhoneNumber", "Carrier", "Status", ""];
    const rows = props.testData.testResults.map((data) => {
      return [
        data.phoneNumber,
        data.carrier,
        getStatus(data.testStatus),
        <div>
          {data.details && (
            <button
              className={style["detail-btn"]}
              onClick={(event) => showDetail(event, data.details)}
            >
              Details
            </button>
          )}
          <Popover
            sx={{ backgroundColor: "#0000" }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorPosition={{ left: 150, top: 700 }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <ResultDetail />
          </Popover>
        </div>,
      ];
    });

    return (
      <React.Fragment>
        <div className={`${neuStyles["neumorphic-outer"]} ${style["result-wrapper"]}`}>
          <div>
            <TestSummary summary={testSummary} key={props.testData.requestId}/>
          </div>
          <div className={style["result-table-wrapper"]}>
          <div className={style["result-table"]}>
            <SimpleTable columns={columns} rows={rows} key={props.testData.requestId} id={props.testData.requestId}/>
          </div>
          <CircularPrgress rate={(successTestCount * 100) / props.testData.testResults.length}/>
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    return <React.Fragment></React.Fragment>;
  }
}
