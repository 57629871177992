import React from "react";
import neuStyles from "../../../css/Neumorphic.module.css";
import SimpleTable from "../../utilities/SimpleTable";
import useHttp from "../../../hooks/useHttp";
import { configApi } from "../../../constants/backend";
import { ServiceResponse } from "../../../model/BackendResponse";
import { useState, useEffect } from "react";
export default function Service() {
    const[serviceList, setServiceList]= useState([]);
    const { isLoading, error, sendRequest } = useHttp();
  try {
    useEffect(()=>{
        sendRequest(
          {
            url: configApi.getServices.url,
            authentication: configApi.getServices.authentication,
          },
          transformGetServiceHttpData
        )
      },[])

    const transformGetServiceHttpData=(data)=>{
      let serviceResponse=new ServiceResponse(data);
      setServiceList(serviceResponse.serviceList);
    }
    const columns = ["ServiceName","Service Code","Service Type", "Description"];
    const rows = serviceList.map((data) => {
      return [
        data.serviceName,
        data.serviceCode,
        data.serviceType,
        data.description
      ];
    });

    return (
      <React.Fragment>
        <div
          style={{
            margin: "auto",
            width: "95%",
            marginBottom: "20px",
            marginTop: "20px",
            height: "30vh",
            borderRadius: "10px",
            overflow: "scroll",
          }}
          className={neuStyles["neumorphic-outer"]}
        >
          <div
            style={{
              overflow: "scroll",
              width: "90%",
              height: "16vh",
              borderRadius: "10px",
              margin: "auto",
              marginTop:"20px"
            }}
          >
            <SimpleTable columns={columns} rows={rows} />
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.log(e)
    return <React.Fragment></React.Fragment>;
  }
}
