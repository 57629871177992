import neuStyles from "../../css/Neumorphic.module.css";
import vonage_message from "../../assets/images/vonage_message.png";
import vonage_phone from "../../assets/images/vonage_phone.png";
import style from "../../css/account/Subscription.module.css";
export default function Subscription(props) {
  return (
    <>
      <div className={`${neuStyles["neumorphic-outer"]} ${style["wrapper"]}`}>
        <div className={style["header-wrapper"]}>
          <div className={style["header-title"]}>
            {props.subscription.serviceCode}
          </div>
          <img
            className={style["header-logo"]}
            src={
              props.subscription.serviceCode.substring(3, 6) === "SMS"
                ? vonage_message
                : vonage_phone
            }
          />
          <div>
            <div className={style["status"]}>
              {"Status:"}
              <div
                className={
                  style[
                    { color: props.subscription.subscriptionActive }
                      ? "status-active"
                      : "status-inactive"
                  ]
                }
              >
                {props.subscription.subscriptionActive ? "Active" : "Deactive"}
              </div>
            </div>
          </div>
        </div>
        <div className={style["circular-progress"]}>
          <div className={style["circular-progress-outer"]}>
            <div className={style["circular-progress-inner"]}>
              <div
                className={style["profile-card__txt"]}
                style={{ paddingTop: "50px" }}
              >
                <strong>{props.subscription.limit}</strong>
                {`${
                  props.subscription.serviceCode.substring(3, 6) === "SMS"
                    ? " SMS "
                    : " Calls "
                }Limit`}
              </div>
              <div className={style["profile-card__txt"]}>
                <strong>{props.subscription.transactionCount}</strong>Used
              </div>
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="200px"
              height="200px"
              className={style["circular-progress-bar"]}
            >
              <defs>
                <linearGradient id="GradientColor">
                  <stop offset="0%" stop-color="#e91e63" />
                  <stop offset="100%" stop-color="#673ab7" />
                </linearGradient>
              </defs>
              <circle
                cx="100"
                cy="100"
                r="85"
                stroke-linecap="round"
                className={style["circular-progress-circle"]}
                style={{
                  strokeDashoffset:
                    ((props.subscription.limit -
                      props.subscription.transactionCount) *
                      528) /
                    props.subscription.limit,
                }}
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}
