import React, { useState, useEffect, useRef, useContext } from "react";
import  NeumorphicCard  from "../../utilities/NeumorphicCard";
import { Stack } from "@mui/material";
import DataListSelector from "../../utilities/DataListSelector";
import VonageLoader  from "../../utilities/VonageLoader";
import useHttp from "../../../hooks/useHttp";
import { api } from "../../../constants/backend";
import useCSVParser from "../../../hooks/useCSVParser";
import TestRunContext from "../../../context/TestRunContext";
import { CarrierResponse } from "../../../model/BackendResponse";
import neuStyle from "../../../css/Neumorphic.module.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import AuthContext from "../../../context/AuthContext";
import { ExecuteTestResponse } from "../../../model/BackendResponse";
import { debounce } from "../../../service/FunctionUtility";
import { HTTP_REQUEST_STATUS } from "../../../constants/status";
import CellTowerIcon from '@mui/icons-material/CellTower';
import Tooltip from "@mui/material/Tooltip";
import Badge from '@mui/material/Badge';
import { ExecuteTestRequest } from "../../../model/BackendRequest";
import AlertContext from "../../../context/AlertContext";
import Dialer from "./utility/Dialer";
import { DataFilterTable } from "../../utilities/DataFilterTable";
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export default function TestExecuter() {
  
  const { parseFile, isParsing } = useCSVParser();
  const { isLoading, error, sendRequest } = useHttp();
  const fileUploadRef = useRef();
  /*Context*/
  const authContext = useContext(AuthContext);
  const testRunCtx = useContext(TestRunContext);
  const sendAlert= useContext(AlertContext).setAlert;
  /*Constants*/
  const LOCAL_FAV_CARRIER=authContext.userName+"_FAVCARRIER_";
  const LOCAL_SVC_CARRIER="CARRIER_";
  /*State*/
  const [activeService, setActiveService] = useState();
  const [favTestNodeList, setFavTestNodeList] = useState([]);
  const [testNodeList, setTestNodeList] = useState([]);
  const [availableTestNode, setAvailableTestNodes] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [enteredNumber, setEnteredNumber] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(()=>{
    setAvailableTestNodes(testNodeList.filter((data)=>!favTestNodeList.includes(data.networkName)))
  },[testNodeList,favTestNodeList])
  const transformGetCarrierHttpData = (data) => {
    let response = new CarrierResponse(data);
    let carrierList = response.carrierList;
    if (response.status === HTTP_REQUEST_STATUS.success) {
      window.localStorage.setItem(LOCAL_SVC_CARRIER + activeService, JSON.stringify({carrierList:carrierList,timestamp: new Date().getTime()}));
      setFavTestNodeList(prev=>prev.filter(value=>response.carrierList.includes(value)))
      setTestNodeList(
        response.carrierList.map((item, index) => {
          return {
            id: index + 1,
            networkName: item,
          };
        })
      );
    }
  };
  useEffect(() => {
    if(activeService)
    {
      setFavTestNodeList(
        window.localStorage.getItem(LOCAL_FAV_CARRIER + activeService)
          ? window.localStorage.getItem(LOCAL_FAV_CARRIER + activeService).split(",")
          : []
      );
      let carrierData;
      try{
        carrierData=JSON.parse(window.localStorage.getItem(LOCAL_SVC_CARRIER + activeService))
      }
      catch(err)
      {
        carrierData=null;
      }
      if (!carrierData||!carrierData.timestamp||!carrierData.carrierList||Math.floor((new Date() - new Date(carrierData.timestamp))/1000/60/60/24)>=1) {
        sendRequest(
          {
            url: api.getCarriersByService.apiName + activeService,
            authentication: api.getCarriersByService.authentication,
          },
          transformGetCarrierHttpData
        );
      } else {
        setTestNodeList(carrierData.carrierList.map((item, index) => {
          return {
            id: index + 1,
            networkName: item,
          };
        }));
      }
    }
    else{
      setFavTestNodeList([])
    }
  }, [activeService]);

  const [bulkTestFile, uploadBulkTestFile] = useState(null);

  const resetForm = () => {
    setEnteredNumber("");
    uploadBulkTestFile(null);
  };


  const executeTest = (testType) => {
   if (isFormValid(testType)) {
      let numberList = [];
      if (enteredNumber.startsWith("File:")) {
        const parsedData = parseFile(bulkTestFile);
        numberList = parsedData.map((data) => data.Number);
      } else {
        numberList = [enteredNumber];
      }
      numberList.forEach((data) => {
        let executeRequest = new ExecuteTestRequest(
          activeService,
          data,
          testType,
          selectedNodes
        );
        sendRequest(
          {
            url: api.execute.url,
            method: api.execute.method,
            authentication: api.execute.authentication,
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
            body: executeRequest.requestBody,
          },
          transformExecuteHttpData
        );
      });
      resetForm();
    }
  };

  const transformExecuteHttpData = (data) => {
    testRunCtx.pushTest(new ExecuteTestResponse(data));
  };

  const fileUploadHandler = () => {
    fileUploadRef.current.click();
  };

  /*Form Validator*/
  const isFormValid = (testType) => {
    if (!activeService) {
      sendAlert("error", "Select a service to execute test");
      return false;
    }
    if (activeService.substring(3, 6) !== testType.substring(0,3)) {
      sendAlert("error", `Wrong service.Select a ${testType.substring(0,3)==="SMS"?"SMS":"VOICE"} test service`);
      return false;
    }
    if (!enteredNumber) {
      sendAlert("error", "Enter phone number");
      return false;
    }
    if (!selectedNodes.length) {
      sendAlert("error", "Select atleast one carrier");
      return false;
    }
    return true;
  };

  useEffect(() => {
    error && sendAlert("Request Cannot Be Processed Now!!");
  }, [error]);

  const changePhoneNumberHandler = (data) => {
    setEnteredNumber(data);
    if (!data.startsWith("File:")) {
      uploadBulkTestFile(null);
    }
  };

  const updateFavTestNodeList = (carrier, isAdd) => {
    setFavTestNodeList((prev) => {
    var temp = [];
      if (isAdd) 
        temp = [...prev, carrier];
      else 
        temp = prev.filter((value) => value != carrier);
      window.localStorage.setItem(LOCAL_FAV_CARRIER + activeService, temp);
      return temp;
    });
  };

  const addCarrierHandler=(event)=>
  {
    if(activeService)
      setAnchorEl(event.currentTarget);
    else
      sendAlert("warning", "Select a service");

  }
  const onCheckedHandler=(value,isChecked)=>updateFavTestNodeList(value.networkName,isChecked)


  return (
    <React.Fragment>
      <input
        ref={fileUploadRef}
        hidden
        type="file"
        accept=".csv"
        onChange={(e) => {
          changePhoneNumberHandler(`File: ${e.target.files[0].name}`);
          uploadBulkTestFile(e.target.files[0]);
          e.target.value = "";
        }}
      />
      <VonageLoader open={isLoading || isParsing} />
      <NeumorphicCard>
        <Dialer
          executeTest={executeTest}
          enteredNumber={enteredNumber}
          changePhoneNumberHandler={changePhoneNumberHandler}
        />

        <Stack spacing={6} sx={{ marginTop: "8%" }}>
          <div>
            <Badge
              badgeContent={
                (activeService &&
                  (activeService.substring(3, 6) === "SMS"
                    ? "SMS"
                    : "VOICE")) ||
                ""
              }
              color={
                (activeService &&
                  (activeService.substring(3, 6) === "SMS"
                    ? "success"
                    : "secondary")) ||
                "error"
              }
            >
              <Autocomplete
                onChange={debounce((event, newValue) => {
                  setActiveService(newValue);
                })}
                disablePortal
                size="small"
                id="combo-box-demo"
                options={authContext.authority.filter(
                  (data) =>
                    data.substring(3, 6) === "SMS" ||
                    data.substring(3, 6) === "VOI"
                )}
                sx={{
                  width: 210,
                  height: "1px",
                  paddingTop: 2,
                  margin: "auto",
                }}
                renderInput={(params) => (
                  <TextField
                    className={neuStyle["neumorphic-inner"]}
                    sx={{ borderRadius: "0px"}}
                    {...params}
                    label="Select Test Service"
                  />
                )}
              />
            </Badge>
          </div>
        </Stack>

        <DataListSelector
          data={favTestNodeList}
          deleteData={(carrier)=>updateFavTestNodeList(carrier,false)}
          setSelectedData={setSelectedNodes}
        />
        <Tooltip title={"Add Carriers"} placement="bottom">
          <button
            type="button"
            className={neuStyle["neumorphic-btn"]}
            style={{ width: "3.5vw", height: "3vw" }}
            onClick={addCarrierHandler}
          >
            <CellTowerIcon />
          </button>
        </Tooltip>
        <Popover
        sx={{backgroundColor:"#0000"}}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorPosition={{left: 150, top: 700}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
<div>
                <DataFilterTable
          rows={availableTestNode}
          initialData={favTestNodeList}
          setSelectedData={onCheckedHandler}
        />
                    <Button sx={{marginTop:"10px",marginBottom:"10px"}}variant="outlined" onClick={handleClose} startIcon={<CheckCircleRoundedIcon />}>
        DONE
      </Button>
      </div>
      </Popover>

      </NeumorphicCard>
    </React.Fragment>
  );
}
