import Paper from "@mui/material/Paper";
import React from "react";
import style from "../../css/Neumorphic.module.css";

export default function NeumorphicCard(props) {
  const cardWidth = props.width ? props.width : "46%";
  const cardHeight = props.height ? props.height : "100%";
  const cardMarginTop = props.marginTop ? props.marginTop : "0%";
  return (
    <Paper
      className={style["neumorphic-card"]}
      elevation={3}
      sx={{
        width: cardWidth,
        height: cardHeight,
        margin:"auto",
        borderRadius: "50px",
        marginTop: cardMarginTop,
        backgroundColor: "#f2f3f7",
      }}
    >
      {props.children}
    </Paper>
  );
}
