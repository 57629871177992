import React, { useRef, useState,useEffect } from "react";
import NeumorphicCard from "../../utilities/NeumorphicCard";
import neuStyle from "../../../css/Neumorphic.module.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import BadgeIcon from "@mui/icons-material/Badge";
import Modal from "../../utilities/Modal";
import AlertManager from "../../utilities/AlertManager";
import useHttp from "../../../hooks/useHttp";
import SubscriptionForm from "./utilities/SubscriptionForm";
import { CreateClientRequest } from "../../../model/BackendRequest";
import { configApi } from "../../../constants/backend";
import VonageLoader from "../../utilities/VonageLoader";
const customStyle = {
  button: {
    style: { width: "6vw", height: "3vw", marginLeft: "10px" },
    styleClass: neuStyle["neumorphic-btn"],
  },
  subscriptionForm: {
    style: {
      margin: "auto",
      width: "80%",
      marginBottom: "20px",
      marginTop: "20px",
      height: "65%",
      borderRadius: "10px",
    },
    styleClass: neuStyle["neumorphic-outer"],
  },
  table: {
    style: {
      overflow: "scroll",
      width: "70%",
      height: "30vh",
      borderRadius: "10px",
      margin: "auto",
      marginTop: "10px",
      border: "solid",
    },
  },
  formBox: {
    style: { "& .MuiTextField-root": { m: 1, width: "25ch" } },
  },
  titleText: {
    style: {
      fontWeight: "bolder",
      color: "#a3aab9",
    },
  },
  clientName: {
    label: "Client Name",
    InputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <BadgeIcon />
        </InputAdornment>
      ),
    },
  },
  clientMail: {
    label: "Client Email",
    InputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <ContactMailIcon />
        </InputAdornment>
      ),
    },
  },
};

export default function ClientCreateForm () {
  const [openModal, setOpenModal] = useState(false);
  const { isLoading, error, sendRequest } = useHttp();
  const[loading, setLoading]=useState(false);
  const clientName = useRef();
  const clientMail = useRef();
  const [subscription, setSubscription] = useState([]);
  useEffect(()=>{error!==null&& sendAlert("Request cannot be processed now!!","error")},[error])
  const [formError, setFormError] = useState({
    clientName: {
      error: false,
      errorMsg: "",
    },
    clientMail: {
      error: false,
      errorMsg: "",
    },
  });
  const [showAlert, setAlert] = useState({
    show: false,
    messgae: "",
    severity: "",
  });
  const sendAlert = (messgae = "Some Error Occured!", severity = "error") => {
    setAlert({
      show: true,
      messgae: messgae,
      severity: severity,
    });
  };
  const isValidateForm = () => {
    const enteredClientName = clientName.current.value;
    const enteredClientMail = clientMail.current.value;
    let isFormValid = true;
    let clientNameErr = false;
    let clientMailErr = false;
    let clientNameErrMsg = "";
    let clientMailErrMsg = "";
    if (!enteredClientName) {
      clientNameErr = true;
      clientNameErrMsg = "Client name cannot be empty!";
      isFormValid = false;
    } else if (enteredClientName.length <= 4) {
      clientNameErr = true;
      clientNameErrMsg = "Client name too short!";
      isFormValid = false;
    }
    if(enteredClientMail && !/\S+@\S+\.\S+/.test(enteredClientMail))
    {
      clientMailErr=true;
      clientMailErrMsg= "Not a valid email!"
      isFormValid = false;
    }
    
    setFormError({
      clientName: {
        error: clientNameErr,
        errorMsg: clientNameErrMsg,
      },
      clientMail: {
        error: clientMailErr,
        errorMsg: clientMailErrMsg,
      },
    });
    if (!subscription || subscription.length < 1) {
      sendAlert("Client has to subscribe to atleast one service");
      isFormValid = false;
    }
    return isFormValid;
  };
  const createClientHandler = () => {
    if (isValidateForm()) {
      //setOpenModal(true);
      setLoading(true)
      const subscriptionList=subscription.map(subc=>{return {serviceCode:subc.serviceCode,limit:subc.limit}})
      const createClientRequest=new CreateClientRequest(clientName.current.value,clientMail.current.value,subscriptionList);
      sendRequest(
        {
          url: configApi.configClient.url,
          method: configApi.configClient.method,
          authentication: configApi.configClient.authentication,
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: createClientRequest.requestBody,
        },
        transformCreateClientHttpData
      );
    }
  };
  const transformCreateClientHttpData=(data)=>{
    setLoading(false)
    if(data.status="SUCCESS")
    {
      const blob = new Blob([JSON.stringify({clientId:data.clientId,secret:data.clientResponse})]);
      const fileDownloadUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileDownloadUrl;
      link.setAttribute(
        'download',
        `${clientName.current.value}_secret.txt`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      sendAlert("Client created.","success")
      resetForm();
    }
    else{
      sendAlert("Client creation failed.","error")
    }
  }
  const resetForm = () => {
    clientName.current.value = "";
    clientMail.current.value = "";
    setSubscription([]);
    setFormError({
      clientName: {
        error: false,
        errorMsg: "",
      },
      clientMail: {
        error: false,
        errorMsg: "",
      },
    });
  };
  return (
    <React.Fragment>
      <VonageLoader open={isLoading} />
      <AlertManager data={showAlert} setAlertHandler={setAlert} />
      <Modal
        open={openModal}
        setOpen={(data) => setOpenModal(data)}
        title={"Confirm!!"}
        sx={{ position: "absolute" }}
      >
        Hello
      </Modal>
      <NeumorphicCard width="60%" height="75%" marginTop="20px">
        <Box
          component="form"
          sx={customStyle.formBox.style}
          noValidate
          autoComplete="off"
        >
          <TextField
            label={customStyle.clientName.label}
            InputProps={customStyle.clientName.InputProps}
            inputRef={clientName}
            error={formError.clientName.error}
            helperText={formError.clientName.errorMsg}
          />
          <TextField
            label={customStyle.clientMail.label}
            InputProps={customStyle.clientMail.InputProps}
            inputRef={clientMail}
            error={formError.clientMail.error}
            helperText={formError.clientMail.errorMsg}
          />
        </Box>
        <div
          style={customStyle.subscriptionForm.style}
         
        >
          <SubscriptionForm
            subscription={subscription}
            setSubscription={setSubscription}
          />
        </div>
        <button
          type="button"
          className={customStyle.button.styleClass}
          style={customStyle.button.style}
          onClick={createClientHandler}
        >
          Create
        </button>
        <button
          type="button"
          className={customStyle.button.styleClass}
          style={customStyle.button.style}
          onClick={resetForm}
        >
          Reset
        </button>
      </NeumorphicCard>
    </React.Fragment>
  );
};
