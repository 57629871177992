import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './styles.css';
import neuStyle from "../../css/Neumorphic.module.css";

var checkboxSelection = function (params) {
  return params.columnApi.getRowGroupColumns().length === 0;
};

const DataFilterTable = (props) => {
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: 900, height: 400,textAlign: "left", margin:"auto" }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%',padding:"10px" }), []);

  const onCheckedHandler=(event)=>{
    if(!props.initialData.includes(event.node.data.networkName))
    setTimeout(()=>props.setSelectedData(event.node.data,event.node.isSelected()),600)
  }
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Carrier',
      field: 'networkName',
      minWidth: 170,
      checkboxSelection: checkboxSelection,
      floatingFilter: true,
    }
  ]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    };
  }, []);
  const paginationNumberFormatter = useCallback((params) => {
    return '[' + params.value.toLocaleString() + ']';
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.paginationGoToPage(0);
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <div style={containerStyle} >
      <div className="example-wrapper">
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            rowData={props.rows}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            rowSelection={'multiple'}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            pagination={true}
            paginationPageSize={100}
            paginationNumberFormatter={paginationNumberFormatter}
            onFirstDataRendered={onFirstDataRendered}
            onRowSelected={onCheckedHandler}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export {DataFilterTable}