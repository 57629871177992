import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import neuStyle from "../../css/Neumorphic.module.css";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TimelineIcon from "@mui/icons-material/Timeline";
import NeumorphicCard  from "../utilities/NeumorphicCard";
import CellTowerIcon from '@mui/icons-material/CellTower';
import { useState } from "react";
import AddClient from "./addClient/AddClient";
import ManageClient from "./manageClient/ManageClient";
import Service from "./service/Service";

const tabStyle = {
  styleClass: [neuStyle["neumorphic-btn"]].join(" "),
  style: { margin: "auto", width: "20%", marginTop:"30px", marginBottom:"10px"  },
  header: {
    style: { margin: "auto", width: "80%" },
  },
};

export default function Admin() {
  
  const tab=[<AddClient/>,<ManageClient/>,<></>,<Service/>];

  const [activeTab, setActiveTab] = useState(tab[0]);

  const tabChangeHandler = (event, newValue) => {
    setActiveTab(tab[newValue]);
  };
  return (
    <React.Fragment>
      <NeumorphicCard width="97%" height="85%" marginTop="20px">
          <Tabs value={activeTab} onChange={tabChangeHandler} style={tabStyle.header.style}>
            <Tab
              className={tabStyle.styleClass}
              sx={tabStyle.style}
              icon={<GroupAddIcon sx={{color:"#FF5F1F"}}/>}
              label="Add Client"
            />
            <Tab
              className={tabStyle.styleClass}
              sx={tabStyle.style}
              icon={<ManageAccountsIcon sx={{color:"#881fff"}}/>}
              label="Manage Client"
            />
            <Tab
              className={tabStyle.styleClass}
              sx={tabStyle.style}
              icon={<TimelineIcon sx={{color:"#FF355E"}}/>}
              label="Client Stats"
            />
            <Tab
              className={tabStyle.styleClass}
              sx={tabStyle.style}
              icon={<CellTowerIcon sx={{color:"#4997D0"}}/>}
              label="Service"
            />
          </Tabs>
          {activeTab}
      </NeumorphicCard>
    </React.Fragment>
  );
}
