import { useEffect, useState } from "react";
import useHttp from "../../hooks/useHttp";
import { ClientDetail } from "../../model/BackendResponse";
import { api } from "../../constants/backend";
import NeumorphicCard from "../utilities/NeumorphicCard";
import IconButton from "@mui/material/IconButton";
import VonageLoader from "../utilities/VonageLoader";
import style from "../../css/account/Account.module.css";
import Subscription from "./Subscription";
import EditIcon from "@mui/icons-material/Edit";
import EditAccount from "./EditAccount";
import Modal from "../utilities/Modal";
export default function Account() {
  const { isLoading, error, sendRequest } = useHttp();
  const [pageLoading, setPageLoading] = useState(true);
  const [accountData, setAccountData] = useState();
  const [openModal, setOpenModel] = useState(false);
  const transformGetAccountHttpData = (data) => {
    setAccountData(new ClientDetail(data));
    setPageLoading(false);
  };
  const test = [
    {
      serviceCode: "ARPVOI",
      limit: 100,
      transactionCount: 0,
      subscriptionActive: true,
    },
    {
      serviceCode: "ARPVOI",
      limit: 100,
      transactionCount: 0,
      subscriptionActive: true,
    },
    {
      serviceCode: "ARPVOI",
      limit: 100,
      transactionCount: 0,
      subscriptionActive: true,
    },
  ];

  useEffect(() => {
    sendRequest(
      {
        url: api.account.url,
        method: api.account.method,
        authentication: api.execute.authentication,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      },
      transformGetAccountHttpData
    );
  }, []);

  const onAccountEditHandler=(editType)=>{
    setOpenModel(true)
  }
  return (
    <NeumorphicCard width="60%" height="75%" marginTop="30px">
      <Modal open={openModal} setOpen={setOpenModel}>
        <EditAccount/>
      </Modal>
      <VonageLoader open={isLoading || pageLoading} />
      <div className={style["profile-card__name"]}>
        {accountData ? accountData.clientName : ""}
      </div>
      <div className={style["profile-card__txt"]}>
        <strong>UserName: </strong>
        {accountData ? accountData.clientId : ""}
      </div>
      <div className={style["profile-card__txt"]}>
        <strong>Password: </strong>
        {"******************"}
        <IconButton aria-label="delete" size="small" onClick={()=>onAccountEditHandler("pass")}>
          <EditIcon fontSize="small" />
        </IconButton>
      </div>
      <div className={style["profile-card__txt"]}>
        <strong>Contact: </strong>
        {accountData ? accountData.contact : ""}
      </div>
      <div className={style["profile-card-inf"]}>
        <div className={style["profile-card-inf__item"]}>
          <div className={style["profile-card-inf__title"]}>Subscriptions</div>
        </div>
      </div>
      <div className={style["grid"]}>
        {accountData &&
          accountData.subscriptionList.map((data) => (
            <Subscription subscription={data} />
          ))}
      </div>
    </NeumorphicCard>
  );
}
