import React from "react";
import { Switch } from "react-router-dom";
import Header from "./main/Header";
import Security from "./security/Security";
import { routesConfig } from "../constants/routes";
import { PrivateRouter,PublicRouter } from "./security/SecureRouter";
import AlertManager from "./utilities/AlertManager";

const AppRoutes = () => (
  <Switch>
    {routesConfig.map((route, idx) => {
      if (route.private) {
        return (
          <PrivateRouter
            key={idx}
            path={`/${route.path}`}
            exact={route.exact}
            component={route.component}
            subRoutes={route.subRoutes}
          />
        );
      }
      return (
        <PublicRouter
          key={idx}
          path={`/${route.path}`}
          exact={route.exact}
          component={route.component}
          subRoutes={route.subRoutes}
        />
      );
    })}
  </Switch>
);

export default function Main() {
  return (
    <React.Fragment>
      <Security>
        <Header />
        <AlertManager>
        <AppRoutes />
        </AlertManager>
      </Security>
    </React.Fragment>
  );
}
