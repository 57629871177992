import React, { useContext, useEffect, useState } from "react";
import style from "../../css/account/EditAccount.module.css";
import AlertContext from "../../context/AlertContext";
import useHttp from "../../hooks/useHttp";
import { api } from "../../constants/backend";
import Loader from "../utilities/GenericLoader";

export default function EditAccount() {
  const [currentPass, setCurrentPass]= useState();
  const [newPass, setNewPass]= useState();
  const [confirmNewPass, setConfirmNewPass]= useState();
  const sendAlert= useContext(AlertContext).setAlert;
  const passwordPolicy=/^(?=.*[0-9])(?=.*[!@#$%&])[a-zA-Z0-9!@#$%&*]{8}$/;
  const { isLoading, error, sendRequest } = useHttp();

  const resetForm=()=>{
    setCurrentPass("");
    setNewPass("");
    setConfirmNewPass("");

  }
  const onSetPasswordHandler=()=>{
    if(isLoading)
      return
    if(!currentPass)
    {
      sendAlert("warning","Enter current password")
      return;
    }
    if(!newPass)
    {
      sendAlert("warning","Enter new password")
      return;
    }
    if(!confirmNewPass)
    {
      sendAlert("warning","Confirm new password")
      return;
    }
    if(newPass!==confirmNewPass)
    {
      sendAlert("warning","New password does not match")
      return;
    }
    if(!newPass.match(passwordPolicy))
    {
      sendAlert("warning","New password does not match password policy")
      return;
    }
    sendRequest(
      {
        url: api.updateAccount.url,
        method: api.updateAccount.method,
        authentication: api.updateAccount.authentication,
        body:{current:currentPass,new:newPass},
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      },
      transformGetAccountHttpData
    );
  }
  const transformGetAccountHttpData=(data)=>{
    sendAlert("success","Pasword changed successfully")
    resetForm();
  }
  useEffect(()=>{
    error && sendAlert("error","Invalid password")
  },[error])

  return (
    <React.Fragment>
      <div className={style["wrapper"]}>
        <div className={style["form-wrapper"]}>
          <div className={style["input-wrapper"]}>
            <label>Current Password:</label>
            <input value={currentPass} className={style["form-input"]} type={"password"} onChange={(event)=>setCurrentPass(event.target.value)}></input>
          </div>
          <div className={style["input-wrapper"]}>
            <label>New Password:</label>
            <input value={newPass} className={style["form-input"]} type={"password"} onChange={(event)=>setNewPass(event.target.value)}></input>
          </div>
          <div className={style["input-wrapper"]}>
            <label>Confirm New Password:</label>
            <input value={confirmNewPass} className={style["form-input"]} type={"password"} onChange={(event)=>setConfirmNewPass(event.target.value)}></input>
          </div>

          <button className={style["submit-btn"]} onClick={onSetPasswordHandler} disable={true}>{!isLoading?"Set Password":
          <Loader type={"type2"}/>}
          </button>
          
        </div>
        <div className={style["policy-wrapper"]}>
          <p>Password Policy:</p>
          <ul className={style["policy-list"]}>
            <li>
              <span></span>8 characters in length
            </li>
            <li>
              <span></span>at least 1 lower case letter (a-z)
            </li>
            <li>
              <span></span>at least 1 upper case letters (A-Z)
            </li>
            <li>
              <span></span>at least 1 number (0-9)
            </li>
            <li>
              <span></span>at least 1 special character (!, @, #, $, %, &, *)
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}
