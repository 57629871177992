import React from "react";
import neuStyles from "../../../../css/Neumorphic.module.css";
import Stack from "@mui/material/Stack";
import style from "../../../../css/admin/ClientDataView.module.css"
import Divider from '@mui/material/Divider';
const ClientSummary = (props) => {
  return (
    <React.Fragment>
      <Stack direction="row" spacing={5} sx={{ marginLeft: "40px", marginTop:"20px" }}>
        {props.summary.map((data) => (
          <Stack direction="row" spacing={1}>
            <div className={style["summary-key-text"]}>
              {data.key}
            </div>
            <div className={style["summary-value-text"]}>
              {data.value}
            </div>
          </Stack>
        ))}
      </Stack>
    </React.Fragment>
  );
};

export default function ClientDataView(props) {
  try {
    const clientSummary = [
      { key: "Client Name:", value: props.clientDetail.clientName },
      { key: "Client Id:", value: props.clientDetail.clientId },
      { key: "Contact:", value: props.clientDetail.contact },
    ];

    return (
      <React.Fragment>
        <div className={`${neuStyles["neumorphic-outer"]} ${style["wrapper"]}`}>
          <div>
            <ClientSummary summary={clientSummary} />
            {/* <button>Add subscription</button> */}
          </div>
          <div className={style["subscription"]}>
            {props.clientDetail.subscriptionList.map((data, index) => (
              <>
              <div className={style["subscription-wrapper"]}>
                <div className={style["subscription-item-id"]}>
                  {index + 1 + ". "}
                </div>
                <div className={style["subscription-item-wrapper"]}>
                  <div className={style["subscription-item-key"]}>Service:</div>
                  <div className={style["subscription-item-value"]}>
                    {data.serviceCode}
                  </div>
                </div>
                <div className={style["subscription-item-wrapper"]}>
                  <div className={style["subscription-item-key"]}>Limit:</div>
                  <div className={style["subscription-item-value"]}>
                    {data.limit}
                  </div>
                </div>
                <div className={style["subscription-item-wrapper"]}>
                  <div className={style["subscription-item-key"]}>
                    Transaction Count:
                  </div>
                  <div className={style["subscription-item-value"]}>
                    {data.transactionCount}
                  </div>
                </div>
              </div>
              <Divider />
              </>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    return <React.Fragment></React.Fragment>;
  }
}
