import style from "../../css/Navbar.module.css";
import "font-awesome/css/font-awesome.min.css";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DialpadIcon from "@mui/icons-material/Dialpad";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useHistory } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { useContext, useEffect, useState } from "react";
import { logout } from "../../service/Authenticator";
export default function Navbar() {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const handleOnSubmit = (link) => {
    history.push(link);
  };
  const [isTestingAccount, setIsTestingAccount] = useState(false);
  useEffect(()=>{
    authContext.authority.forEach(data => {
      if(data.substring(3,6)==="SMS"||data.substring(3,6)==="VOI")
      {
        setIsTestingAccount(true);
      }
    });
  },[authContext]);
  return (
    <div className={style["navbar"]}>
      {/* {isTestingAccount &&
      <>
      <li className={style["list-item"]} onClick={()=>handleOnSubmit('/test')}>
        <DialpadIcon />
        <span className={style["list-item-name"]}>Dialer</span>
      </li>
      <li className={style["list-item"]} onClick={()=>handleOnSubmit('/report')}>
        <AssessmentIcon />
        <span className={style["list-item-name"]}>Reports</span>
      </li>
      </>} */}
            <li className={style["list-item"]} onClick={()=>handleOnSubmit('/test')}>
        <DialpadIcon />
        <span className={style["list-item-name"]}>Dialer</span>
      </li>
      <li className={style["list-item"]} onClick={()=>handleOnSubmit('/report')}>
        <AssessmentIcon />
        <span className={style["list-item-name"]}>Reports</span>
      </li>
      {authContext.authority.includes("NUMAUTO") &&
      <li className={style["list-item"]} onClick={()=>handleOnSubmit('/admin')}>
        <AdminPanelSettingsIcon />
        <span className={style["list-item-name"]}>Admin</span>
      </li>}
      <li className={style["list-item"]}>
        <AccountBoxIcon />
        <span className={style["list-item-name"]} onClick={()=>handleOnSubmit('/account')}>Account</span>
      </li>
      <li className={style["list-item"]} onClick={()=>{handleOnSubmit('/');logout()}}>
        <ExitToAppIcon />
        <span className={style["list-item-name"]}>Logout</span>
      </li>
    </div>
  );
}
