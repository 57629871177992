export class ClientDetailResponse {
  status;
  message;
  clientDetailList=[];
  constructor(data) {
    this.clientDetailList = data.clientDetailList.map((clientDetail) => new ClientDetail(clientDetail));
    this.status = data.status;
    this.message = data.message;
  }
}

export class ClientDetail{
  clientName;
  clientId;
  subscriptionList;
  active;
  contact;
  constructor(data) {
    this.clientName = data.clientName;
    this.clientId = data.clientId;
    this.subscriptionList = data.subscriptionList.map((subscription) => new ClientSubscription(subscription));
    this.active=data.active
    this.contact=data.contact
  }
}

class ClientSubscription{
  serviceCode;
  limit;
  transactionCount;
  subscriptionActive;
  constructor(data) {
    this.serviceCode = data.serviceCode;
    this.limit = data.limit;
    this.transactionCount = data.transactionCount;
    this.subscriptionActive = data.subscriptionActive;
  }
}

export class ServiceResponse {
  status;
  message;
  serviceList;
  constructor(data) {
    this.status = data.status;
    this.message = data.message;
    this.serviceList = data.serviceList.map((service) => new Service(service));
  }
}
class Service {
  serviceName;
  serviceCode;
  serviceType;
  description;
  constructor(data) {
    this.serviceName = data.serviceName;
    this.serviceCode = data.serviceCode;
    this.serviceType = data.serviceType;
    this.description = data.description;
  }
}

export class TestResultResponse {
  status;
  message;
  tests;
  constructor(data) {
    this.status = data.status;
    this.message = data.message;
    this.tests = data.tests.map((test) => new Test(test));
  }
}
export class TokenResponse {
  status;
  message;
  token;
  refreshToken;
  constructor(data) {
    this.token = data.token;
    this.refreshToken = data.refreshToken;
  }
}
export class TokenClaim {
  clientId
  subscriptionList
  constructor(data) {
    this.clientId = data["Client Id"];
    this.subscriptionList = data["Subscription"].split(",").map(data=>data.substring(5));
  }
}
export class CarrierResponse {
  status;
  message;
  carrierList = [];
  constructor(data) {
    this.status = data.status;
    this.message = data.message;
    this.carrierList = data.carrierList;
  }
}
export class ExecuteTestResponse {
  status;
  message;
  requestId;
  constructor(data) {
    this.status = data.status;
    this.message = data.message;
    this.requestId = data.requestId;
  }
}
class Test {
  requestId;
  testResults;
  testDate;
  constructor(data) {
    this.requestId = data.requestId;
    this.testDate=data.testDate;
    this.testResults = data.testResults?data.testResults.map(
      (result) => new TestResults(result)
    ):[];
  }
}

class TestResults {
  phoneNumber;
  carrier;
  message;
  testStatus;
  details;
  constructor(data) {
    this.phoneNumber = data.phoneNumber;
    this.carrier = data.carrier;
    this.message = data.message;
    this.testStatus = data.testStatus;
    this.details=data.details;
  }
}
