import {useState } from "react";
import Papa from "papaparse";

export default function useCSVParser() {

    const parseResult=(results)=>{
        setParsedData(results.data)
        setIsParsing(false)

    }
    const [isParsing, setIsParsing] = useState(false)
    const [parsedData, setParsedData]= useState();
    const parseFile =(file)=>{
        setIsParsing(true)
        setParsedData(null)
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: parseResult,
          });
          while(isParsing)
          {

          }
          return parsedData
    }
    return {
        parseFile,
    }
}