import * as React from "react";
import neuStyles from "../../css/Neumorphic.module.css";
import { Tooltip } from "@mui/material";

export default function ProgressBar(props) {
  const getStatusColor = (rate) => {
    if (rate === 100)
      return "linear-gradient(83deg, rgba(249,249,249,1) 0%, rgba(0,185,29,1) 44%)";
    else if (rate >= 70 && rate < 100)
      return "linear-gradient(83deg, rgba(249,249,249,1) 0%, rgba(199,223,11,1) 44%)";
    else if (rate >= 50 && rate < 70)
      return "linear-gradient(83deg, rgba(249,249,249,1) 0%, rgba(242,164,1,1) 42%)";
    else if (rate >= 20 && rate < 50)
      return "linear-gradient(83deg, rgba(249,249,249,1) 0%, rgba(253,117,29,1) 100%)";
    else if (rate < 20)
      return "linear-gradient(83deg, rgba(249,249,249,1) 0%, rgba(253,29,29,1) 100%)";
  };
  return (
    <Tooltip title={`SuccessRate-${props.successRate }%`} placement="top">
    <div className={neuStyles["neumorphic-progress"]} style={{width:props.width?props.width:"35vw"}}>
     
      <div
        style={{
          width: `${props.successRate===0?5:props.successRate / 1.03}%`,
          backgroundImage: getStatusColor(props.successRate),
        }}
        className={neuStyles["neumorphic-progress__line"]}
      />
     
    </div>
    </Tooltip>
  );
}
