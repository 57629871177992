import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontFamily: '"Quicksand", sans-serif'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    maxWidth: 200,
    fontWeight:"bolder",
    fontFamily: '"Quicksand", sans-serif'
  },
}));

export default function SimpleTable(props) {
  return (
      <Table size="small" stickyHeader sx={{fontFamily: '"Quicksand", sans-serif',}}>
        <TableHead>
          <TableRow>
            {props.columns.map((column,index)=><StyledTableCell key={props.id+index}>{column}</StyledTableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow key={row[0]+props.id+[row[1]]} >
              {row.map((data,index)=>
                <StyledTableCell key={props.id+index} component="th" scope="row">
                {data}
              </StyledTableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
  );
}