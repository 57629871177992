import style from "../../../../css/StatusLoader.module.css";

export default function StatusLoader(props) {
  const statusProp = {
    Success: {
      style: [style.loading, style.success].join(" "),
    },
    PartialSuccess: {
      style: [style.loading, style.partial].join(" "),
    },
    Running: {
      style: [style.loading, style.running].join(" "),
    },
    Waiting: {
      style: [style.loading, style.running].join(" "),
    },
    Failed: {
      style: [style.loading, style.failed].join(" "),
    },
  };

  const activeStatus = statusProp[props.status.state];
  return (
    <div>
    <div className={activeStatus.style} style={{ paddingTop: "34px" }}>

      <div >{props.status.text.toUpperCase()}</div>

    </div>
    </div>
  );
}
