import React, { createRef, useEffect, useState } from "react";
import style from "../../css/Login.module.css";
import Loader from "../utilities/GenericLoader";
import { authenticate } from "../../service/Authenticator";
import { raiseEvent } from "../../service/EventManager";
import { SecurityEvents } from "../../constants/customEvents";
import Alert from '@mui/material/Alert';
export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [loginFailed, setIsLoginFailed] = useState(false);
  const userName = createRef();
  const password = createRef();
  const loginHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let authPromise= authenticate(userName.current.value, password.current.value)
    let authenticated= await authPromise;
    if (authenticated) {
      raiseEvent(SecurityEvents.AUTHENTICATED);
    } else {
      setIsLoginFailed(true);
    }
    setIsLoading(false);
  };
  useEffect(()=>{setTimeout(()=>setIsLoginFailed(false),5000)},[loginFailed]);
  return (
    <React.Fragment>
      <form className={style["login-form"]} onSubmit={loginHandler}>
        <p className={style["login-text"]}>
          <span className={`${style["fa-stack"]} ${style["fa-lg"]}`}>
            <i className="fa fa-lock fa-stack-1x"></i>
          </span>
        </p>
        <input
          type="usename"
          className={style["login-username"]}
          autofocus="true"
          required="true"
          placeholder="Username"
          ref={userName}
        />
        <input
          type="password"
          className={style["login-password"]}
          required="true"
          placeholder="Password"
          ref={password}
        />
        <input
          type="submit"
          name="Login"
          value="Login"
          className={style["login-submit"]}
        />
        {loginFailed && <Alert variant="outlined" severity="error">Invalid username or password</Alert>}
      </form>
      {isLoading && <Loader />}
    </React.Fragment>
  );
}
