import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import vonageLogo from "../../assets/images/vonage_logo.png";
import style from "../../css/Header.module.css";
import Login from "./Login";
import AuthContext from "../../context/AuthContext";
import { useContext } from "react";
import Navbar from "./Navbar";

const vonageTheme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      contrast: "#f2f3f7",
    },
  },
});

export default function Header() {
  const authContext = useContext(AuthContext);
  return (
    <React.Fragment>
      <ThemeProvider theme={vonageTheme}>
        <AppBar
          color="primary"
          sx={{ backgroundColor: "primary.main", height: "40px" }}
        >
          <Toolbar>
            <Box
              sx={{
                width: 150,
                height: 50,
                marginTop: "20px",
                backgroundColor: "primary.contrast",
                borderRadius: "40px",
                paddingLeft: "1px",
                paddingTop: "10px",
                zIndex: -1,
              }}
            >
              <img
                src={vonageLogo}
                style={{
                  width: 130,
                  height: 30,
                }}
              />
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          className={style[authContext.isAuthenticated ? "loggedIn" : "loggedOut"]}
          sx={{
            width: 430,
            backgroundColor: "primary.main",
            margin: "auto",
            borderRadius: "40px",
          }}
        >
          {!authContext.isAuthenticated && <Login/>}
          {authContext.isAuthenticated && <Navbar/>}
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
}
