import NeumorphicCard from "../../utilities/NeumorphicCard";
import React, { useState, useEffect, useRef, useContext } from "react";
import TestRunContext from "../../../context/TestRunContext";
import TestView from "./utilities/TestLoggerUtility";

export default function TestLogger() {
  const testRunCtx = useContext(TestRunContext);
  const [data, updateData]=useState([]);
  useEffect(()=>{updateData(testRunCtx.testRuns)},[testRunCtx.testRuns]);
  return (
    <React.Fragment>
      <NeumorphicCard style={{overflow: "scroll"}}>
          {data.map(data=> <TestView key={data.requestId} testRunDetail={data}/>)}
      </NeumorphicCard>
    </React.Fragment>
  );
}
