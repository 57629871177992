import { endPoint, api } from "../constants/backend";
import { raiseEvent } from "./EventManager";
import { SecurityEvents } from "../constants/customEvents";
import { isExpired,decodeToken } from "react-jwt";
import { TokenClaim, TokenResponse } from "../model/BackendResponse";

const authenticate = (userName, password) => {
    const headers = { 'Authorization': "Basic " + btoa(userName + ":" + password) }
    const url = `${endPoint}${api.login.url}`
    return getAccess(headers, url);
}
const isUserActive = () => {
    if (!isExpired(window.localStorage.getItem("token"))) {
        return true
    }
    else if (window.localStorage.getItem("token") && !isExpired(window.localStorage.getItem("refresh"))) {
        return true
    }
    return false;
}

const logout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("refresh");
    raiseEvent(SecurityEvents.NOT_AUTHENTICATED)
}

const renewToken = async() => {
    let accessToken = window.localStorage.getItem("token");
    let refreshToken = window.localStorage.getItem("refresh");
    if (isExpired(refreshToken)) {
        raiseEvent(SecurityEvents.NOT_AUTHENTICATED)
        return false;
    }
    const headers = {
        'Authorization': "Bearer " + accessToken,
        'Refresh': refreshToken
    }
    const url = `${endPoint}${api.refreshToken.url}`;
    await getAccess(headers, url).then(result=>{
        if(!result)
            raiseEvent(SecurityEvents.NOT_AUTHENTICATED)
    })
    return true;
}
const getAccessToken = async () => {
    let accessToken = window.localStorage.getItem("token");
    if (!accessToken) {
        raiseEvent(SecurityEvents.NOT_AUTHENTICATED);
        return;
    }
    if (isExpired(accessToken)){
        await renewToken(accessToken)
        accessToken = window.localStorage.getItem("token");
    }
    return accessToken;
}
const getSubscriptionList=()=>{
    try{
        let accessToken = window.localStorage.getItem("token");
        return new TokenClaim(decodeToken(accessToken));
    }
    catch(err)
    {
        raiseEvent(SecurityEvents.NOT_AUTHENTICATED);
    }
}
const getAccess = async (headers, url) => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });
        if (!response.ok) {
            return false;
        }
        const data = await response.json();
        let responseData = new TokenResponse(data)
        if (!responseData.token || !responseData.refreshToken) {
            return false;
        }
        window.localStorage.setItem("token", responseData.token)
        window.localStorage.setItem("refresh", responseData.refreshToken)
        return true;
    }
    catch (err) {
        raiseEvent(SecurityEvents.NOT_AUTHENTICATED);
    }
}

export { authenticate, getAccessToken, isUserActive, getSubscriptionList,logout }