import vonage_message from "../../../../assets/images/vonage_message.png";
import vonage_phone from "../../../../assets/images/vonage_phone.png";
import vonage_upload from "../../../../assets/images/vonage_upload.png";
import neuStyles from "../../../../css/Neumorphic.module.css";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { Stack } from "@mui/material";
import { throttle } from "../../../../service/FunctionUtility";
import { useRef } from "react";
const dialPadIcons = {
  sms: vonage_message,
  call: vonage_phone,
  upload: vonage_upload,
};
const DialPadButton = (props) => {
  const inputRef = useRef();
  return (
    <Tooltip title={props.type} placement="bottom-end">
      <button
        type="button"
        className={neuStyles["neumorphic-btn"]}
        style={{ width: "3vw", height: "2.5vw" }}
        onClick={props.onClickHandler}
      >
        <img src={dialPadIcons[props.type]} width="100%" height="99%" />
      </button>
    </Tooltip>
  );
};
const NumberDisplay = (props) => {
  const width = props.value.startsWith("File:")
    ? (props.value.length * 100) / (13 * 3.7)
    : 30;
  return (
    <input
      className={neuStyles["neumorphic-input"]}
      type="text"
      style={{ height: "40%", width: `${width}%`, margin: "auto" ,fontFamily: "Quicksand, sans-serif"}}
      value={props.value}
      onChange={(event) => props.changeHandler(event.target.value)}
      placeholder={"Phone Number"}
      required
    />
  );
};

export default function Dialer(props) {
  return (
    <>
      <Stack spacing={1} sx={{ marginTop: "30px", height: "10%" }}>
        <NumberDisplay
          value={props.enteredNumber}
          changeHandler={props.changePhoneNumberHandler}
        />
        <div style={{ margin: "auto", marginTop: "1%" }}>
          <Stack
            direction="row"
            spacing={3}
            sx={{ paddingTop: "2%", height: "100%" }}
          >
            <DialPadButton
              type="sms"
              onClickHandler={throttle(() => props.executeTest("SMS"))}
            />
            <DialPadButton
              type="call"
              onClickHandler={throttle(() => props.executeTest("VOICE"))}
            />
            <DialPadButton
              type="upload"
              onClickHandler={throttle(() => props.fileUploadHandler())}
            />
          </Stack>
        </div>
      </Stack>
    </>
  );
}
