import AuthContext from "../../context/AuthContext";
import { useContext } from "react";
import { Route } from "react-router-dom";
export function PublicRouter(props) {
  const { component: Component } = props;

  const render = (props) => {
    return <Component {...props} />;
  };
  return (
    <Route exact path={props.path}>
      {render}
    </Route>
  );
}

export function PrivateRouter(props) {
  const authContext = useContext(AuthContext);
  const { component: Component} = props;

  const render = (props) => {
    return <Component {...props} />;
  };
  return (
    authContext.isAuthenticated && (
      <Route exact path={props.path}>
        {render}
      </Route>
    )
  );
}
