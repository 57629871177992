import  NeumorphicCard  from "../utilities/NeumorphicCard";
import React, { useEffect, useState, useContext } from "react";
import neuStyle from "../../css/Neumorphic.module.css";
import TextField from "@mui/material/TextField";
import { api } from "../../constants/backend";
import useHttp from "../../hooks/useHttp";
import { TestResultResponse } from "../../model/BackendResponse";
import TestResultView from "../tester/testLogger/utilities/TestResultView";
import Autocomplete from "@mui/material/Autocomplete";
import AuthContext from "../../context/AuthContext";
import  VonageLoader  from "../utilities/VonageLoader";
import { debounce } from "../../service/FunctionUtility";


export default function Report() {
  const authContext = useContext(AuthContext);
  const [activeService, setActiveService] = useState(authContext.authority[0]);
  const [testResultData, setTestResultData]=useState();
  const { isLoading, error, sendRequest } = useHttp();
  useEffect(() => {
    if(activeService)
    {
      sendRequest(
        {
          url: api.getTestResultsByService.apiName + activeService,
          authentication: api.getTestResultsByService.authentication,
        },
        transformGetTestResultsByServiceHttpData
      );
    }
  }, [activeService]);
  
  const transformGetTestResultsByServiceHttpData=(data)=>{
    setTestResultData(new TestResultResponse(data).tests)
  }
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (
    event,
    newPage,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
            <div style={{ marginTop: "10px" }}>
          <Autocomplete
            onChange={debounce((event, newValue) => setActiveService(newValue))}
            defaultValue={authContext.authority[0]}
            disablePortal
            size="small"
            options={authContext.authority}
            sx={{ width: 210, height: "1px", paddingTop: 2, margin: "auto" }}
            renderInput={(params) => (
              <TextField
                className={neuStyle["neumorphic-inner"]}
                sx={{ borderRadius: "0px" }}
                {...params}
                label="Select Test Service"
              />
            )}
          />
        </div>
      <NeumorphicCard width="97%" height="75%" marginTop="60px">
        <VonageLoader open={isLoading} />
        {testResultData &&
          testResultData.map((data, index) => (
            <TestResultView testData={data} key={index} />
          ))}
      </NeumorphicCard>

    </>
  );
}
