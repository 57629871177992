
export const endPoint = window._env_.REACT_APP_BACKEND_HOST
export const api = {
    getCarriersByService: {
        apiName: "/test/carrier/",
        method: 'GET',
        authentication:"Token"
    },
    execute: {
        url: "/test/execute",
        method: 'POST',
        authentication:"Token"
    },
    getTestResultsByRequestId: {
        apiName: "/test/result/",
        method: 'GET',
        authentication:"Token"
    },
    getTestResultsByService: {
        apiName: "/test/resultall/",
        method: 'GET',
        authentication:"Token"
    },
    login: {
        url: "/token",
        method: 'GET',
        authentication:"Basic"
    },
    refreshToken: {
        url: "/refreshToken",
        method: 'GET',
        authentication:"Token"
    },
    stopTest: {
        url: "/test/stop/",
        method: 'POST',
        authentication:"Token"
    },
    account: {
        url: "/account",
        method: 'GET',
        authentication:"Token"
    },
    updateAccount: {
        url: "/updateAccount",
        method: 'POST',
        authentication:"Token"
    }
}

export const configApi = {
    getServices: {
        url: "/config/getService",
        method: 'GET',
        authentication:"Token"
    },
    configClient: {
        url: "/config/client",
        method: 'POST',
        authentication:"Token"
    },
    getClientDetails: {
        url: "/config/client",
        method: 'GET',
        authentication:"Token"
    }
}